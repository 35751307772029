// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Details = (props: PageProps) => (
  <Layout>
    <SEO title="All the details" />
    <Link to="/">
      <div className="pt-10 md:pt-20">
    <p className="text-gray-600">⬅ Go back</p>
    <h1 className="text-7xl mb-3 mt-3">the details</h1></div></Link>
    <h2 className="font-semibold text-5xl mb-3 mt-14" style={{fontFamily:"bad script"}}>Welcome Reception</h2>    
    <p className="text-xl font-medium">Friday 2/11 @ 6pm</p>
    <p><span className="text-md text-gray-600">Clay Pit</span></p>
    <p><span className="text-md text-gray-600">1601 Guadalupe St - Austin, TX</span></p>
    <h2 className="font-semibold text-5xl mb-3 mt-3" style={{fontFamily:"bad script"}}>Ceremony & Celebration</h2>
    <p className="text-xl font-medium">Saturday 2/12 @ 5pm</p>
    <p><span className="text-md text-gray-600">South Congress Hotel</span></p>
    <p><span className="text-md text-gray-600">1603 S Congress Ave - Austin, TX</span></p>
    <h2 className="font-semibold text-5xl mb-3 mt-3" style={{fontFamily:"bad script"}}>Parting Brunch</h2>   
    <p className="text-xl font-medium">Sunday 2/13 @ 11am </p>
    <p><span className="text-md text-gray-600">Megan & Corey's House</span></p>
    <p><span className="text-md text-gray-600">3808 Clawson Rd - Austin, TX</span></p>


   
  </Layout>
)

export default Details
